/* updates starts here */
.updates-heading-main {
  border-bottom: 1px solid rgba(232, 232, 232, 1);
}

.updates-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 50px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -1.5px;
  text-align: start;
}

.updates-features-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.77px;
  text-align: left;
}

.updates-para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
}
/* updates end here */

/* faqs starts here */
.faqs-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 50px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -1.5px;
  text-align: left;
}

.faqs-sub-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.77px;
  text-align: left;
}
/* faqs ends here */

/* Online Courses starts here */

.courses-btn-main {
  height: 300px;
  opacity: 1;
  background-color: rgba(235, 235, 235, 1);
}

.btn-course-start {
  opacity: 1;
  background-color: rgba(58, 125, 231, 1);
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  position: absolute;
  right: 2%;
  bottom: 2%;
  border: none;
  border-radius: 0;
  padding: 10px 20px;
}

.btn-course-unlock {
  opacity: 1;
  background-color: #202037;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  position: absolute;
  right: 2%;
  bottom: 2%;
  border: none;
  border-radius: 0;
  padding: 10px 20px;
}

.btn-course-unlock:hover {
  opacity: 1;
  background-color: #323255;
}

.courses-head {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.29px;
  text-align: left;
}

.courses-para {
  opacity: 1;
  color: rgba(99, 99, 99, 1);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
}

/* Online Courses ends here */

@media screen and (max-width: 991px) {
  .updates-heading {
    font-size: 35px;
  }

  .updates-features-heading {
    font-size: 25px;
  }

  .faqs-sub-heading {
    font-size: 25px;
  }
}
