.Templates-showcase-para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: center;
  line-height: 32px;
}

.Templates-showcase-para-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 65px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -1.95px;
  text-align: center;
  line-height: 75px;
}

.template-catogary-btn-active {
  background-color: rgba(32, 32, 55, 1);
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  border: none;
}

.template-catogary-btn {
  opacity: 1;
  background-color: rgba(244, 244, 244, 1);
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  border: none;
}

.theme-name-main {
  background-color: #e5e5e5;
  border: 1px solid #e5e5e5;
}

.theme-name {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.theme-sapn {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: center;
  line-height: 32px;
}

.theme-btn-show-more {
  opacity: 1;
  background-color: #f4f4f4;
  border: none;
  border-radius: 0;
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.theme-btn-main {
  position: absolute;
  bottom: 2%;
  left: 4%;
}

.theme-btn-get-theme {
  opacity: 1;
  background-color: rgba(58, 125, 231, 1);
  border: none;
  border-radius: 0;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.theme-list-button.active {
  opacity: 1;
  background-color: rgba(32, 32, 55, 1);
  border: none;
  border-radius: 0;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  padding: 10px 20px;
}

.theme-list-button {
  opacity: 1;
  background-color: rgba(244, 244, 244, 1);
  border: none;
  border-radius: 0;
  opacity: 1;
  color: rgba(45, 45, 45, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  padding: 10px 20px;
}

/* Showcase details Header start here*/
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.header-showcase {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

@media (max-width: 576px) {
  .header-showcase {
    display: block;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
}

.header-left {
  flex: 1;
}

.back-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.header-center {
  flex: 2;
  text-align: center;
}

.device-button {
  background: none;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.device-button.active {
  border-color: #000;
}

.header-right {
  flex: 1;
  text-align: right;
}

.view-demo {
  background: #f8f8f8;
  border: none;
  border-radius: 0 !important;
color: rgba(36, 36, 40, 1);
font-family: "Poppins";
font-size: 15px;
font-weight: 600;
font-style: SemiBold;
letter-spacing: 0px;
text-align: center;
}

.try-design {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 0 !important;
  opacity: 1;
color: rgba(255, 255, 255, 1);
font-family: "Poppins";
font-size: 15px;
font-weight: 600;
font-style: SemiBold;
letter-spacing: 0px;
text-align: center;
}

.showcase-image-main {
  width: "1050px";
  height: "869px";
}

/* Showcase details Header end here*/
