/* Header Section Start Here */

.headerSection {
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  /* background-image: url('../../assets/img/HeaderSectionImage.svg'); */
}

.headerHeading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 65px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: normal;
  text-align: center;
  line-height: 75px;
}

.headingPara {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: center;
  line-height: 32px;
}

.headerButton {
  opacity: 1;
  background-color: rgba(58, 125, 231, 1);
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding: 10px 20px;
}

.headerButtonPara {
  font-size: 12px;
  color: rgba(88, 88, 88, 1);
}

/* Header Section End Here */

/* Intro Cards Section start Here */

.introCardsPara {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
}

.introCardsHead {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 50px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: normal;
  text-align: left;
  line-height: 61px;
}

.infoCardBorder {
  border-top: 3px solid rgb(202, 196, 196);
}

.infoCardHeading {
  text-align: start;
}

/* Intro Cards Section end Here */

/* Business section start here */

.businessHeadImage {
  width: 100px;
}

.businessHeading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 50px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: normal;
  text-align: center;
  line-height: 61px;
}

.businessPara {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: center;
  line-height: 32px;
}

.addToCartImg {
  width: 500px;
  height: 500px;
  position: relative;
  z-index: 1;
}

.Business-content-Head {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.95px;
  text-align: left;
}

.Business-content-Para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 30px;
}

.rectangleImg {
  width: 15px;
}

/* Business section end here */

/* mozaycTools section Start here*/

.mozaycTools-section {
  opacity: 1;
  background-color: rgba(32, 32, 55, 1);
  position: relative;
  bottom: 8rem;
}

.mozaycTools-para {
  color: rgba(255, 255, 255, 1);
}

.mozaycToolsHead {
  color: rgba(255, 255, 255, 1);
}

.mozaycTools-colorfull-span {
  background: linear-gradient(
    90deg,
    #3a7de7 0%,
    #8337ec 27%,
    #ff026e 53%,
    #fb5607 78%,
    #ffbe0d 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.mozaycToolsButtons {
  border-radius: 30px;
  background-color: #2b2b42;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mozaycToolsButtons:hover {
  cursor: pointer;
  background-color: #3a3a51;
}

.mozaycToolsButtons-span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
}

.container-bottom-border {
  border-bottom: 1px solid rgb(95, 91, 91);
}

.margin-bottom-row {
  margin-bottom: 5rem;
}

.mozaycTools-info {
  opacity: 1;
  background-color: rgba(38, 38, 61, 1);
  height: 450px;
  margin-bottom: 3rem;
}

.mozaycTools-info-heading {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: normal;
  text-align: left;
}

.mozaycTools-info-Para {
  opacity: 0.7;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 30px;
}

.mozaycTools-info-content {
  height: 250px;
}

/* mozaycTools section end here*/

/* checkout section start here*/

.pricingButtonsDiv {
  opacity: 1;
  background-color: rgba(244, 244, 244, 1);
  padding: 5px;
}

.pricingInfoMain {
  border: 1px solid rgba(232, 232, 232, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 450px;
}

.startupDiv {
  border-bottom: 1px solid #e0e0e3;
}

.startup-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.77px;
  text-align: left;
}

.startup-para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 28px;
}

.price-para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: -0.17px;
  text-align: center;
}

.price-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Inter-SemiBold";
  font-size: 30px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.38px;
  text-align: right;
}

.getStartbtnSpan {
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.getStartbtnDiv {
  border: none;
  border-radius: 0;
}

.getstart-span {
  opacity: 1;
  color: rgba(136, 136, 136, 1);
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: center;
}

.features-list {
  list-style: none;
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
}

.dec-para {
  opacity: 1;
  color: rgba(136, 136, 136, 1);
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: center;
}

.pricingInfoMain:hover {
  opacity: 1;
  border: 3px solid rgba(58, 125, 231, 1);
  cursor: pointer;
}

/* checkout section end here*/

/* review section start here*/
.reviewPara {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: -0.82px;
  text-align: left;
  line-height: 48px;
}

.statesdiv {
  opacity: 1;
  background-color: rgba(244, 244, 244, 1);
}

.stats-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.9px;
  text-align: left;
}

.stats-para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 28px;
}

.stats-link-div {
  border-top: 3px solid #000;
}

.stats-link {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: left;
}

/* Style for the dot indicators */
.carousel-indicators-costom button {
  background-color: gray;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0 3px;
  border: none;
  transition: background-color 0.3s ease;
}

/* Active dot indicator */
.carousel-indicators-costom button.active {
  background-color: black !important; /* Color for active dot */
}

/* review section end here*/

.active-btn {
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 28px;
}

.monthly-btn {
  opacity: 1;
  color: rgba(136, 136, 136, 1);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 28px;
  border: none;
}

.yearly-btn {
  opacity: 1;
  color: rgba(136, 136, 136, 1);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 28px;
  border: none;
}
.text-justify{
  text-align: justify !important;
}
/* Blog style start here */

.blogDetails-para {
  opacity: 1;
  color: rgba(99, 99, 99, 1);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
}

.blogDetails-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.29px;
  text-align: left;
}

.BlogImg {
  width: 310px;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.BlogImg img {
  transition: transform 0.5s ease, box-shadow 0.9s ease;
  cursor: pointer;
}

.BlogImg img:hover {
  transform: scale(1.1); /* Zoom effect for the image */
}

.newBlogSpan {
  opacity: 1;
  background-color: rgba(58, 125, 231, 1);
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-section {
  border-top: 1px solid #e0e0e3;
}

.fw-700{
  font-weight: 700;
}

/* Blog style end here */

/* Media Quary css here */

@media screen and (max-width: 768px) {
  .headerHeading {
    font-size: 25px;
    line-height: 2rem;
  }

  .headingPara {
    font-size: 14px;
    line-height: 1.3rem;
  }

  .headerButton {
    font-size: 14px;
  }

  .margin-Head-img {
    margin-top: 14rem !important;
  }

  .introCardsHead {
    font-size: 25px;
    line-height: 2rem;
  }

  .introCardsPara {
    font-size: 14px;
  }

  .addToCartImg {
    width: 340px;
    height: 340px;
    position: relative;
    z-index: 1;
  }

  .mozaycTools-section {
    position: relative;
    bottom: 0;
  }

  .reviewPara {
    font-size: 18px;
    line-height: 30px;
  }

  .BlogImg {
    width: 250px;
    height: 200px;
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .headerHeading {
    font-size: 35px;
    line-height: 3rem;
  }

  .headingPara {
    font-size: 16px;
    line-height: 1.3rem;
  }

  .headerButton {
    font-size: 16px;
  }

  .margin-Head-img {
    margin-top: 8rem !important;
  }

  .introCardsHead {
    font-size: 35px;
    line-height: 3rem;
  }

  .introCardsPara {
    font-size: 16px;
  }

  .addToCartImg {
    width: 430px;
    height: 430px;
    position: relative;
    z-index: 1;
  }

  .mozaycTools-section {
    position: relative;
    bottom: 0;
  }

  .BlogImg {
    width: 280px;
    height: 230px;
  }
}
