.headingHead-helpcenter {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 65px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -1.95px;
  text-align: center;
  line-height: 75px;
}

.headingPara-helpcenter {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-weight: 400;
  text-align: center;
  line-height: 32px;
}

.Helpcenter-cards-bg {
    background-color: #F4F4F4 !important;
    height: 350px !important;
}

.Helpcenter-cards-headings-bg {
  color: #242428;
}

.Helpcenter-cards-para-bg {
  color: #585858;
}
