.footer {
  background-color: #f2f2f2;
}

.footer-upper-heding {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 50px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -1.29px;
  text-align: left;
}

.footer-upper-para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 28px;
}

.footer-upper-div {
  border-bottom: 1px solid rgb(202, 196, 196);
}

.footer-middle-div {
  border-bottom: 1px solid rgb(202, 196, 196);
}

.footer-middle-heding {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.2px;
  text-align: left;
}

.footer-middle-para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-weight: 400;
  text-align: left;
  line-height: 15px;
}

.footer-mddle-last {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.06);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.footer-mddle-last-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.77px;
  text-align: left;
}

.footerLogo {
  position: absolute;
  left: 10%;
  top: -9%;
}

.footer-last-btn {
  opacity: 0.05;
  opacity: 1;
  border-radius: 0;
}

.footer-last-btn-span {
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: left;
}

.footerCopyRightPara {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
}
