/* Features Header section start here */

.featuresHeaderSection {
  background-color: #202037;
  height: 700px;
  padding-top: 100px;
}

.featuresHeaderHeding {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
}

.featuresHeaderPara {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
}

.margin-Head-img-features {
  position: absolute;
  top: 140px;
  left: 0;
}

/* Features Header section end here */

/* All features section start here */

.border-botton-all-features-Likns {
  border-bottom: 1px solid #e0e0e3;
}

.allFeaturesLinks ul li {
  list-style: none;
  opacity: 1;
  color: rgba(34, 34, 34, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: left;
}

/* General styles for the feature links */
.feature-link {
  padding-bottom: 5px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s, border-color 0.3s;
}

.feature-link.active-feature {
  font-weight: bold;
  border-bottom: 2px solid #242428; /* Adjust color as needed */
}

.allFeatureCardsHeading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 65px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -1.95px;
  text-align: left;
  line-height: 75px;
}

.allFeatureCardsPara {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 30px;
}

.allFeature-info {
  opacity: 1;
  background-color: rgba(244, 244, 244, 1);
}

.allFeature-info:hover {
  background-color: #fff !important;
  border: 1px solid rgba(244, 244, 244, 1);
  cursor: pointer;
}

.allFeature-info-heading {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.6px;
  text-align: left;
}

.allFeature-info-Para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 30px;
}

.new-tag {
  opacity: 1;
  background-color: rgba(58, 125, 231, 1);
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}

/* All features section end here */

@media screen and (max-width: 768px) {
  .margin-Head-img-features {
    top: 250px;
  }

  .featuresHeaderSection {
    background-color: #202037;
    height: 450px;
  }

  .allFeaturesLinks ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .feature-link {
    width: 40%; /* Two items per row */
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .margin-Head-img-features {
    top: 110px;
  }
  .featuresHeaderSection {
    background-color: #202037;
    height: 550px;
  }
}
