.border-left-none {
  border-left: none !important;
}

.border-right-none {
  border-right: none !important;
}

.border-top-none {
  border-top: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-bottom-price {
  border-bottom: 1px solid rgba(232, 232, 232, 1);
}

.pricingInfoMain-pricing {
  border: 1px solid rgba(232, 232, 232, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 100%;
}

.height-price-head-max {
  height: 330px;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
}

.hight-price-first-max {
  height: 350px;
}

.hight-price-second-max {
  height: 280px;
}

.hight-price-third-max {
  height: 170px;
  background-color: #f4f4f4;
}

.hight-price-fourth-max {
  height: 200px;
}

.hight-price-fifth-max {
  height: 260px;
}

.hight-price-sixth-max {
  height: 210px;
}

.pricing-subHeadings {
  opacity: 1;
  color: rgba(34, 34, 34, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: left;
}

.most-popular {
  position: absolute;
  top: -8%;
  opacity: 1;
  background-color: rgba(36, 36, 40, 1);
  width: 100%;
  display: flex;
  justify-content: center;
}

.most-popular span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.marketing-hover-head {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: left;
}

.marketing-hover-para {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
}

.marketing-hover {
  display: none;
  opacity: 1;
  background-color: rgba(36, 36, 40, 1);
  width: 260px;
  height: 150px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(98%, -120%);
}

.marketing-hover:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0; /* Triangle size */
  border-color: rgba(36, 36, 40, 1) transparent transparent transparent; /* Match the background color */
}

.on-marketing-hover:hover .marketing-hover {
  cursor: pointer;
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

/* Faqs start here */

.faqs-head {
  opacity: 1;
  color: rgba(26, 29, 41, 1);
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.6px;
  text-align: center;
}

.faq-section {
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.faq-item {
  border-top: 1px solid #ddd;
  padding: 15px 0;
}

.faq-question {
  display: flex;
  cursor: pointer;
  opacity: 1;
  color: rgba(34, 34, 34, 1);
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.73px;
  text-align: left;
}

.plus-minus-faqs {
  font-size: 1.5rem;
  color: #000;
}

/* Active styles */
.faq-question.active-faq {
  color: #3a7de7; /* Change text color */
  font-weight: bold;
  border-color: #3a7de7; /* Optional border highlight */
}

.plus-minus-faqs.active-icon {
  color: #3a7de7; /* Change icon color */
}

.plus-minus-faqs {
  font-size: 24px;
  font-weight: 300;
}

.faq-answer {
  text-align: start;
  font-size: 16px;
  color: #555;
}

.faq-answer-para {
  opacity: 1;
  color: rgba(88, 88, 88, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 30px;
}

.show-more {
  text-align: center;
  margin-top: 20px;
}

.show-more button {
  border: none;
  padding: 15px 20px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(244, 244, 244, 1);
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.show-more button:hover {
  background-color: #e0e0e0;
}

/* Faqs end here */

/* Pricing second style start here*/

.startupDiv {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Creates a subtle bottom shadow */
}

/* Pricing second style end here*/

@media screen and (max-width: 991px) {
  .height-price-head-max {
    height: auto;
    border-bottom: 1px solid rgba(232, 232, 232, 1);
  }

  .hight-price-first-max {
    height: auto;
  }

  .hight-price-second-max {
    height: auto;
  }

  .hight-price-third-max {
    height: auto;
  }

  .hight-price-fourth-max {
    height: auto;
  }

  .hight-price-fifth-max {
    height: auto;
  }

  .hight-price-sixth-max {
    height: auto;
  }

  .marketing-hover {
    display: none;
    opacity: 1;
    background-color: rgba(36, 36, 40, 1);
    width: 260px;
    height: 150px;
    position: absolute;
    top: 80%;
    right: 870%;
    transform: translate(98%, -120%);
  }

  .marketing-hover:after {
    content: "";
    position: absolute;
    bottom: -10px; /* Place the triangle below the div */
    right: 0 !important; /* Align it to the right */
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 0; /* Adjust triangle size */
    border-color: rgba(36, 36, 40, 1) transparent transparent transparent; /* Match background */
  }

  .faq-question {
    font-size: 18px;
    font-weight: 600;
  }
}

.veryfy-span {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.44px;
  text-align: left;
}
