.headerFeatures-custom {
    opacity: 1;
    background-color: transparent;
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  .header-links {
      list-style: none;
    position: relative;
    padding-bottom: 5px;
  }
  
  /* Active category styles */
  .header-links-features.active-category {
    font-weight: bold;
    border-bottom: 3px solid #ffffff; /* Change this color as needed */
    transition: border-color 0.3s;
  }
  
  
  .header-links-features a {
    opacity: 1;
    color: #FFFFFF !important;
  }
  
  .header-links a:hover {
    color: rgba(58, 125, 231, 1) !important;
  }
  
  /* Hamburger Button Style */
  .hamburger-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }
  
  .hamburger-icon-features {
    background-color: #f2f2f2;
  }
  
  .hamburger-icon-features::before,
  .hamburger-icon-features::after {
    background-color: #f2f2f2;
  }
  
  .hamburger-icon::before {
    transform: translateY(-8px);
  }
  
  .hamburger-icon::after {
    transform: translateY(8px);
  }
  
  .hamburger-icon-features.open {
    background-color: transparent;
  }
  
  .hamburger-icon-features.open::before {
    transform: rotate(45deg);
    top: 0;
  }
  
  .hamburger-icon-features.open::after {
    transform: rotate(-45deg);
    top: 0;
  }
  
  /* Dropdown Menu for Small Screens */
  .dropdown-menu-small {
      background-color: #202037;
      padding: 20px;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      align-items: start;
    }
    
    .header-links-list.dropdown {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    /* .header-links-list .dropdown-links {
      margin: 10px 0;
    } */
    
    .header-links-list .dropdown-links a {
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      padding: 10px;
    }
    