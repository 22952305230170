.header-custom {
  opacity: 1;
}

.header-button-start {
  border-radius: 0;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(58, 125, 231, 1);
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  padding: 10px 20px;
}

.header-button-login {
  opacity: 1;
  border: none;
  border-radius: 0;
  background-color: rgba(244, 244, 244, 1);
  padding: 10px 20px;
}

.header-button-login-dark {
  border: none;
  border-radius: 0;
  opacity: 1;
  background-color: rgba(244, 244, 244, 0.08);
  color: rgba(255, 255, 255, 1) !important;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
  padding: 10px 20px;
}

.header-button-login-dark:hover {
  background-color: rgba(244, 244, 244, 0.15);
}

.header-links {
  position: relative;
  padding-bottom: 10px;
}

.header-links a {
  opacity: 1;
  color: rgba(36, 36, 40, 1) !important;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.active-category {
  border-bottom: 2px solid rgba(36, 36, 40, 1);
  opacity: 1;
}

.header-links a:hover {
  color: rgba(58, 125, 231, 1) !important;
}

/* Hamburger Button Style */
.hamburger-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.hamburger-icon {
  width: 25px;
  height: 2px;
  background-color: #202037;
  position: relative;
  display: block;
  transition: all 0.3s ease;
}

.hamburger-icon::before,
.hamburger-icon::after {
  content: "";
  width: 25px;
  height: 2px;
  background-color: #202037;
  position: absolute;
  transition: all 0.3s ease;
}

.hamburger-icon::before {
  transform: translateY(-8px);
}

.hamburger-icon::after {
  transform: translateY(8px);
}

.hamburger-icon.open {
  background-color: transparent;
}

.hamburger-icon.open::before {
  transform: rotate(45deg);
  top: 0;
}

.hamburger-icon.open::after {
  transform: rotate(-45deg);
  top: 0;
}

/* Dropdown Menu for Small Screens */
.dropdown-menu-small {
  background-color: #202037;
  padding: 20px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.header-links-list.dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .header-links-list .dropdown-links {
    margin: 10px 0;
  } */

.header-links-list .dropdown-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
}
