/* Login style starts here */
.login-body {
  background-color: #f4f4f4;
}

.login-main {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.06);
  opacity: 1;
  background-color: #ffffff;
}

.logo-login {
  position: absolute;
  top: -3%;
}

.login-head {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0.77px;
  text-align: left;
}

.login-para {
  opacity: 1;
  color: rgba(76, 76, 76, 1);
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 30px;
}

.email-label {
  opacity: 1;
  color: rgba(76, 76, 76, 1);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: -0.38px;
  text-align: left;
}

/* Default styling for inputs */
.email-input {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(230, 230, 230, 1);
  border-bottom: 2px solid #dedede;
  opacity: 1;
  opacity: 1;
  color: rgba(76, 76, 76, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: left;
}

/* Remove all borders except bottom on focus */
.email-input:focus {
  border: none;
  border-bottom: 2px solid #242428;
  outline: none;
}

.button-login-google {
  opacity: 1;
  border: none;
  border-radius: 0;
  background-color: #f4f4f4;
  padding: 10px 20px;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

.divider hr {
  border: none;
  border-top: 1px solid #ccc;
  flex-grow: 1;
}

.divider span {
  margin: 0 10px;
  font-size: 14px;
  color: #888;
}

/* Login style ends here */

/* Get Started style starts here */

.get-start-turms-para {
  opacity: 1;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: center;
}

.nextButton {
  opacity: 1;
  background-color: rgba(58, 125, 231, 1);
  border: none;
  border-radius: 0;
  padding: 10px 25px;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.skipButton {
  opacity: 1;
  background-color: #fff;
  border: none;
  border-radius: 0;
  padding: 10px 25px;
  color: rgba(36, 36, 40, 1);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

/* Get Started style endss here */
